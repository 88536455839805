import "../assets/css/main.css";

export default function MidBanner(props) {
  return (
    <section id="one" className={props.wrapper_style}>
      <div className="inner">
        <img className="image" alt="" src={props.image} />
        <div className="content">
          <h2 className="major">{props.title}</h2>
          {/* <p>
            Lorem ipsum dolor sit amet, etiam lorem adipiscing elit. Cras turpis
            ante, nullam sit amet turpis non, sollicitudin posuere urna. Mauris
            id tellus arcu. Nunc vehicula id nulla dignissim dapibus. Nullam
            ultrices, neque et faucibus viverra, ex nulla cursus.
          </p> */}
        </div>
      </div>
    </section>
  );
}
