import React, { useState } from "react";
import "../assets/css/main.css";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    email: "",
    message: "",
  });

  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here (e.g., send data to a server)
    console.log("Form data submitted:", formData);
    // You can reset the form if needed
    setFormData({
      name: "",
      company: "",
      email: "",
      message: "",
    });

    // Set formSubmitted to true
    setFormSubmitted(true);
  };

  return (
    <section id="footer">
      <div className="inner">
        <h2 className="major">Get in touch</h2>
        {formSubmitted ? (
          <form>
            <div className="fields">
              <div className="field">
                <h2>Message sent. Thank you!</h2>
              </div>
            </div>
          </form>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="fields">
              <div className="field">
                <label>
                  Name:
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </label>
              </div>
              <br />

              <div className="field">
                <label>
                  Company:
                  <input
                    type="text"
                    name="company"
                    value={formData.company}
                    onChange={handleChange}
                  />
                </label>
              </div>
              <br />

              <div className="field">
                <label>
                  Email:
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </label>
              </div>
              <br />

              <div className="field">
                <label>
                  Message:
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  />
                </label>
              </div>
              <br />

              <div className="field">
                <button type="submit">Submit</button>
              </div>
            </div>
          </form>
        )}

        <ul className="contact">
          <li className="icon solid fa-phone">(908)-543-4051</li>
          <li className="icon solid fa-envelope">
            contact@tamioengineering.com
          </li>
        </ul>
        <ul className="copyright">
          <li>&copy; 2023 Tamio Engineering, LLC All rights reserved.</li>
        </ul>
      </div>
    </section>
  );
};

export default ContactForm;
