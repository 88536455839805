// import { useState, useEffect } from "react";

import WelcomeBanner from "../components/WelcomeBanner";
import MidBanner from "../components/MidBanner";

import conceptgeneration from "../assets/images/concept-generation.jpg";
import pcba from "../assets/images/pcba.jpg";
import prototyping from "../assets/images/prototyping.jpg";
import ContactForm from "../components/ContactForm";

function WelcomePage() {
  return (
    <section>
      <WelcomeBanner />
      <section id="wrapper">
        <MidBanner
          title="Concept Generation & Product Development"
          wrapper_style="wrapper spotlight style1"
          image={conceptgeneration}
        />
        <MidBanner
          title="Electrical & Software Engineering"
          wrapper_style="wrapper alt spotlight style2"
          image={pcba}
        />
        <MidBanner
          title="Prototyping"
          wrapper_style="wrapper spotlight style1"
          image={prototyping}
        />
        <ContactForm />
        {/* <MeetupList meetups={loadedMeetups} /> */}
      </section>
    </section>
  );
}

export default WelcomePage;
